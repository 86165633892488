<template>
    <div class="examinerManagement">
        <div class="flex-box">
            <div class="left">
            </div>
        </div>
        <el-table
            :data="classMonthCheckList"
            style="width: 100%">
            <el-table-column
                label="考核人"
                prop="mname"
                align="center"></el-table-column>
            <el-table-column
                label="状态"
                prop="statusStr"
                align="center">
                <template slot-scope="scope">
                    <div>{{scope.row.status == 2 ? '已完成' : '未完成'}}</div>
                </template>
            </el-table-column>
            <el-table-column
                label="操作"
                width="180px"
                align="center">
                <template slot-scope="scope">
                    <el-button
                    :disabled="scope.row.status === 1"
                    size="mini"
                    type="primary"
                    @click="lookScore(scope.row)">查看
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            layout="prev,pager,next"
            background
            :page-count="page.pageCount"
            :page-size="page.currentPage"
            @current-change="classMonthCheck"
        ></el-pagination>

        <el-dialog
          title=""
          :visible.sync="evaluationManagementScoreDialogVisible"
          width="1190px"
        >
          <div class="center form-height">
            <div class="title">班级文化建设考核标准</div>
            <table style="width:100%" border="1" cellspacing="0" cellpadding="3">
              <thead>
                <tr>
                  <th colspan="2">班级</th>
                  <th colspan="1">{{className}}</th>
                  <th>评分时间</th>
                  <th colspan="3">{{scoreTime}}</th>
                </tr>

                <tr>
                  <th colspan="1">项目</th>
                  <th colspan="1">序号</th>
                  <th>标准条文</th>
                  <th>分值</th>
                  <th>计分说明</th>
                  <th>得分情况</th>
                </tr>

              </thead>

              <tbody>
                <template v-for="(item,index) in classCultureSummaryScoreDetailList" >
                  <tr v-for="(jtem,index2) in item.list" :key="index2">
                    <th v-if="index2==0 && index==0" rowspan="5">{{item.project}}</th>
                    <th v-if="index2==0 && index==1" rowspan="4">{{item.project}}</th>
                    <th v-if="index2==0 && index==2" rowspan="10">{{item.project}}</th>
                    <th v-if="index2==0 && index==3" rowspan="10">{{item.project}}</th>

                    <th v-if="index==0" :key="index2">{{index2+1}}</th>
                    <th v-if="index==1" :key="index2">{{index2+1+5}}</th>
                    <th v-if="index==2" :key="index2">{{index2+1+4+5}}</th>
                    <th v-if="index==3" :key="index2">{{index2+1+4+5+10}}</th>

                    <th :key="index2">{{jtem.name}}</th>
                    <th :key="index2">{{jtem.all_score}}</th>
                    <th :key="index2">{{jtem.explain}}</th>
                    <th :key="index2" class="evaluationScoreStyle">
                      <el-input :disabled="true" class="classCultureSummaryScoreStyle" v-model="jtem.score"></el-input>
                    </th>
                  </tr>
                </template>

              </tbody>
            </table>
          </div>
        
        </el-dialog>

    </div>
</template>

<script>
export default {
    name:'templateclassCultureSummaryScore',
    data(){
        return {
            page: { pageCount: 1, currentPage: 0 },
            // 月份id
            id:Number(this.$route.query.id),
            // 月份名称
            classid:this.$route.query.classid,
            // 学期id
            yearid:Number(this.$route.query.yearid),
            people_id:Number(this.$route.query.people_id),
            className:this.$route.query.className,
            scoreTime:this.$route.query.scoreTime,
            // 班级月份考核列表
            classMonthCheckList:[],
            // 对话框显示与隐藏
            evaluationManagementScoreDialogVisible:false,
            classCultureSummaryScoreDetailList:[]
            
        }
    },
    created(){
        this.classMonthCheck()
        // console.log(this.scoreTime)
    },
    methods:{
      classMonthCheck(){
        this.$post(`/base/queryClassStandardPeople.do`,{id:this.id})
        .then(res=>{
            console.log(res)
            this.classMonthCheckList = res.data
        })
      },
      // 查看分数
      lookScore(row){
          this.evaluationManagementScoreDialogVisible = true
          this.$post(`/base/queryClassStandardScoreByCheck.do`,{
              pid:row.pid,
              classid:this.classid,
              yearid:this.yearid,
              people_id:row.id
          }).then(res=>{
              console.log(res)
              let arr = []
              res.data.forEach(item=>{
                  // item.score = ''
                  let index = arr.findIndex(jtem=>jtem.type == item.type)
                  if(index == -1){
                      if(item.type == 1){
                          let obj = {
                              type:item.type,
                              project:'班级常规',
                              list:[item]
                          }
                          arr.push(obj)
                      }else if(item.type == 2){
                          let obj = {
                              type:item.type,
                              project:'环境文化',
                              list:[item]
                          }
                          arr.push(obj)
                      }else if(item.type == 3){
                          let obj = {
                              type:item.type,
                              project:'文化活动',
                              list:[item]
                          }
                          arr.push(obj)
                      }

                  }else{
                      arr[index].list.push(item)

                  }
                
              })
              console.log(arr)
              let obj = {
                  type:4,
                  project:'综合',
                  list:[{
                      name:'班级总分',
                      all_score:'',
                      explain:'',
                      all_score:100,
                      score:''
                  }]
              }
              arr.push(obj)
              this.classCultureSummaryScoreDetailList = arr
              this.getTotalScore()

          })

      },
      // 计算总分
      getTotalScore(){
        let total = 0
        for(let i = 0; i < this.classCultureSummaryScoreDetailList.length - 1; i++){
          this.classCultureSummaryScoreDetailList[i].list.forEach(jtem=>{
            total += Number(jtem.score)
          })
        }
        this.classCultureSummaryScoreDetailList[this.classCultureSummaryScoreDetailList.length-1].list[0].score = total
        // this.totalScore = total
      },
    }
}
</script>

<style scoped>
.form-height{
  height: 600px;
  overflow-x:auto;
}

.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

/* .center {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.center .title{
  text-align: center;
  font-size:20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.center td{
  padding:8px 0px;
  padding-left: 6px;
  text-align: center;
}

.center td /deep/ .el-input{
  width: 85px;
  margin: 0 6px;
}

.center .evaluationScoreStyle{
  padding: 2px 0px;
}

.center .evaluationScoreStyle .classCultureSummaryScoreStyle{
  width: 60px;
}

.evaluationScoreStyle .el-input /deep/ .el-input__inner{
  padding: 3px 5px;
}

.picker {
  margin-left: 10px;
}

.display {
    display: inline-block;
}

.marginRight {
    margin-right: 10px;
}
</style>
